import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ImSpinner8 } from "react-icons/all";
import Config from '../config/site'
const ContactForm = (props) => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // @SET input values : Parth Patel
  const setInput = (name, value) => {
    console.log(name, value);
    switch (name) {
      case "fullname":
        setFullname(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "subject":
        setSubject(value);
        break;
      case "message":
        setMessage(value);
        break;
    }
  };

  const resetForm = () => {
    setFullname("");
    setEmail("");
    setPhone("");
    setSubject("");
    setMessage("");
    setError(false);
    setLoading(false);
  };
  // @ACTION handle form submition : Parth Patel
  const handleSubmit = async (e) => {
    setLoading(true);
    if (!fullname || !email || !subject || !message) {
      setError(true);
      setLoading(false);
      return false;
    }

    let data = {
      fullname: fullname,
      email: email,
      phone: phone ? phone : "-",
      subject: subject,
      message: message,
    };
    const endUrl ="/.netlify/functions/inquiry";
    setLoading(true);
    postData(endUrl, JSON.stringify(data))
      .then((result) => {
        console.log(result);
        if (result && result.status === "SUCCESS") {
          toast.success(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          resetForm();
        } else {
          toast.error("Something went wrong, Please try agan later..", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // setLoading(false);
        }
      })
      .catch((error) => console.error(error));
  };
  const postData = async (url = ``, data = {}) => {
    const response = await fetch(url, {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "no-referrer",
      body: data,
    });

    return response.json();
  };
  return (
    <div className="flex flex-wrap ">
      <div className="mb-6 w-1/2 pr-2 xs:w-full xs:pr-0">
        <label
          htmlFor="fullname"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Fullname *
        </label>
        <input
          type="text"
          id="fullname"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Nijo Jonson"
          required=""
          value={fullname}
          onChange={(e) => setInput("fullname", e.target.value)}
        />
        {error && !fullname && (
          <span className="text-sm text-red-400 pl-1 absolute">
            Enter full name
          </span>
        )}
      </div>
      <div className="mb-6 w-1/2 pl-2 xs:w-full xs:pl-0">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Email *
        </label>
        <input
          type="email"
          id="email"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="name@domain.com"
          required=""
          value={email}
          onChange={(e) => setInput("email", e.target.value)}
        />
        {error && !email && (
          <span className="text-sm text-red-400 pl-1 absolute">
            Enter email address
          </span>
        )}
      </div>
      <div className="mb-6 w-full">
        <label
          htmlFor="phone"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Phone
        </label>
        <input
          type="text"
          id="phone"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your Phone"
          required=""
          value={phone}
          onChange={(e) => setInput("phone", e.target.value)}
        />
      </div>
      <div className="mb-6 w-full">
        <label
          htmlFor="subject"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Subject *
        </label>
        <input
          type="text"
          id="subject"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your subject"
          required=""
          value={subject}
          onChange={(e) => setInput("subject", e.target.value)}
        />
        {error && !subject && (
          <span className="text-sm text-red-400 pl-1 absolute">
            Enter subject
          </span>
        )}
      </div>
      <div className="mb-6 w-full">
        <label
          htmlFor="message"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
        >
          Message *
        </label>
        <textarea
          id="message"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Your message"
          required=""
          value={message}
          onChange={(e) => setInput("message", e.target.value)}
        />
        
     
        {error && !message && (
          <span className="text-sm text-red-400 pl-1 absolute">
            Enter message
          </span>
        )}
      </div>

      <button
        disabled={loading}
        type="button"
        onClick={(e) => {
          handleSubmit(e);
        }}
        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 spin-button"
      >
        {loading ? <ImSpinner8 className="animate-spin " size={24} /> : null}
        Send Message
      </button>
    </div>
  );
};

export default ContactForm;
