import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import BreadCrumb from "../components/BreadCrumb";
import SEO from "../components/Seo";
import {
  ImOffice,
  BiTimeFive,
  FiMapPin,
  FiPhoneCall,
  HiOutlineMail,
  AiOutlineFieldTime,
  FaBusinessTime,
} from "react-icons/all";
import ContactForm from "../components/ContactForm";
const ContactPage = (props) => {
  const { data } = props;
  const address_details = data?.settings?.nodes?.[0]?.address_details;
  const business_hours = data?.settings?.nodes?.[0]?.business_hours;

  return (
    <Layout details={data}>
         <SEO
        pathname="/"
        title={`Nastesco Marine Pte Ltd | Contact Us`}
        keywords={["Nastesco Contact Page"]}
        />
      <BreadCrumb
        list={[
          {
            title: "Contact Us",
            href: "/contact",
            active: true,
          },
        ]}
      />
      <div className="  relative z-[1] bg-white">
        <div className=" mx-auto  max-w-7xl px-12 py-12 pb-4 xs:px-3 xs:py-3">
          <div className="flex flex-wrap   relative  ">
            <div className="w-full   rounded-lg overflow-hidden shadow-md">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14765.508760797258!2d73.2006977!3d22.3015703!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xda6f0c9dbf36e286!2sNastesco+Marine+Engineering+Pvt+Ltd!5e0!3m2!1sen!2sin!4v1553365821603"
                width="100%"
                height="300"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </div>

            <div className="w-full md:w-2/3  py-8 xs:pb-0">
              <div className=" p-4  mb-4 xs:mb-0 ">
                <p className="flex flex-nowrap items-center text-gray-600 text-md font-semibold border-b pb-2">
                  <div className="service-icon-round p-2">
                    <ImOffice size={24} className="p-[3px]" />
                  </div>
                  &nbsp;Office
                </p>
                <div className="mb-2">
                  <p className="flex flex-nowrap items-center text-gray-600 text-md">
                    <div className="text-indigo-600 p-2">
                      <FiMapPin size={24} className="p-[3px]" />
                    </div>
                    <p className="font-semibold ">Address</p>
                  </p>
                  <p className="pl-10 text-gray-500 text-sm">
                    {address_details?.address_text +
                      ", " +
                      address_details.city +
                      ", " +
                      address_details.state +
                      ", " +
                      address_details.country}
                  </p>
                </div>
                <div className="mb-2">
                  <p className="flex flex-nowrap items-center text-gray-600 text-md">
                    <div className="text-indigo-600 p-2">
                      <FiPhoneCall size={24} className="p-[3px]" />
                    </div>
                    <p className="font-semibold ">Phone</p>
                  </p>
                  <p className="pl-10 text-gray-500 text-sm">
                    {address_details?.site_phone}
                  </p>
                </div>
                <div className="mb-2">
                  <p className="flex flex-nowrap items-center text-gray-600 text-md">
                    <div className="text-indigo-600 p-2">
                      <HiOutlineMail size={24} className="p-[3px]" />
                    </div>
                    <p className="font-semibold ">Email</p>
                  </p>
                  <p className="pl-10 text-gray-500 text-sm">
                    {address_details?.site_email}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full md:w-2/6 py-8 xs:pb-0">
              <div className="p-4  mb-4 xs:mb-0">
                <p className="flex flex-nowrap items-center text-gray-600 text-md font-semibold border-b pb-2 mb-2">
                  <div className="service-icon-round p-2">
                    <FaBusinessTime size={24} className="p-[2px]" />
                  </div>
                  &nbsp;Business Hours
                </p>
                <div className="mb-2">
                  <p className="flex flex-nowrap items-center text-gray-600 text-md">
                    <div className="text-indigo-600 p-2">
                      <BiTimeFive size={24} className="p-[3px]" />
                    </div>
                    <p className="font-semibold ">Open</p>
                  </p>
                  <p className="pl-10 text-gray-500 text-sm">
                    {business_hours?.open}
                  </p>
                </div>
                <div className="mb-2">
                  <p className="flex flex-nowrap items-center text-gray-600 text-md">
                    <div className="text-indigo-600 p-2">
                      <BiTimeFive size={24} className="p-[3px]" />
                    </div>
                    <p className="font-semibold ">Closed</p>
                  </p>
                  <p className="pl-10 text-gray-500 text-sm">
                    {business_hours?.closed}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="  relative z-[1] bg-gray-100 px-12 py-16 xs:px-3 xs:py-8 contact-section">
        <div className=" mx-auto shadow-lg rounded-xl max-w-2xl px-12 py-16 xs:px-3 xs:py-8 xs:shadow-none">
        <ContactForm />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    settings: allSanitySettings {
      nodes {
        id
        address_details {
          address_text
          city
          country
          office_location {
            latitude
            longitude
          }
          site_email
          site_phone
          state
        }
        business_hours {
          closed
          open
        }
        copyright_year
        shipserve_details {
          logo {
            asset {
              url
            }
          }
          trade_id
        }
        site_logo {
          asset {
            url
          }
        }
        site_name
        social_links {
          facebook_link
          linkedin_link
          tweeter_link
        }
      }
    }
    company_profile: allSanityCompanyProfile {
      nodes {
        about_us
        full_description {
          description
          description_image {
            asset {
              url
            }
          }
        }
        policy_details {
          description
          policy_image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;
export default ContactPage;
